@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --primary-color: #f89700;
  --primary-color-hover: #2f50f5;
  --secondary-color: #202020;
  --dark-color: #202020;
  --light-color: #646464;
  --font-family: "Roboto", sans-serif;
  --font-heading: "Roboto", sans-serif;
  --toastify-font-family: "Roboto", sans-serif !important;
  --toastify-font-size: 12px !important;
}
.pointer {
  cursor: pointer;
}
.toast_class {
  font-size: 12px !important;
  font-weight: 400;
  margin-top: 50px !important;
}
#root {
  height: 100%;
}
.relative {
  position: relative;
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
.wd100 {
  width: 100% !important;
}
.bg_white {
  background: #fff;
}
.font-12 {
  font-size: 12px;
}
body {
  font-family: var(--font-family);
  font-size: 13px;
  box-sizing: border-box;
  /* height: 100%; */
  margin: 0;
  padding: 0;
  background: #ffffff;
  overflow-x: hidden;
  margin-top: 100px;
  color: #545454;
}
.text-secondary {
  color: var(--secondary-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  letter-spacing: 1px;
  margin-bottom: 0px;
  margin-top: 0;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-space-between-stretch {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.flex-start {
  display: flex;
  justify-content: start;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gap_20 {
  gap: 20px;
}
.gap_15 {
  gap: 15px;
}
.gap_5 {
  gap: 5px;
}
.gap_10 {
  gap: 10px;
}
.gap_25 {
  gap: 25px;
}
.gap_45 {
  gap: 45px;
}
.line_height_text {
  line-height: 20px;
  color: #32403b;
}
a {
  color: #3151f8;
  text-decoration: none;
}
a:hover {
  color: var(--primary-color);
  text-decoration: none;
}
.link_class {
  color: var(--light-color);
  text-decoration: none;
  display: block;
}
.link_class:hover {
  color: var(--primary-color);
  text-decoration: none;
}
*:focus {
  border: 1px solid #ccc;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1),
    0 5px 10px -20px rgba(0, 0, 0, 0.05);
  outline: none;
}
a:focus {
  border: none;
  outline: none;
  box-shadow: none !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.container {
  width: 100%;
  height: 100%;
}
.wrapper {
  width: 1170px;
  margin: auto;
  box-sizing: border-box;
  height: 100%;
}
.wrapper_fluid {
  width: 90%;
  margin: auto;
  box-sizing: border-box;
  height: 100%;
}
.wrapper_full {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  height: 100%;
}
.flex_center_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.margin_auto {
  margin: auto;
}
.mt-10px {
  margin-top: 10px;
}
.mt-20px {
  margin-top: 20px;
}
.mt-10p {
  margin-top: 10%;
}
.mt-5p {
  margin-top: 5%;
}
.mt-20 {
  margin-top: 20px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60% !important;
}
.w70 {
  width: 70%;
}
.w40 {
  width: 40%;
}
.w35 {
  width: 35%;
  max-width: 450px;
}
.w30 {
  width: 30%;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p25 {
  padding: 25px;
}
.p30 {
  padding: 30px;
}
.p40 {
  padding: 40px;
}
.card {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 0.1),
    0 5px 40px -20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  float: left;
  box-sizing: border-box;
  width: 100%;
}
.card_r {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 0.1),
    0 5px 40px -20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  float: left;
}

.login-container {
  margin-top: 10%;
}
.login-container h1 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.login-container p {
  text-align: center;
  color: var(--light-color);
  margin: 0;
}
.login-container label {
  margin-bottom: 7px;
  width: 100%;
  float: left;
  color: #010101;
}
.form-group {
  margin-bottom: 15px;
  float: left;
  width: 100%;
  position: relative;
}
.form-group .icon_input {
  position: absolute;
  right: 12px;
  top: 40px;
}
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.form-control {
  padding: 10px 18px;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 15.341px;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  font-family: var(--font-family);
}
.form-control-less {
  padding: 10px 12px;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  font-family: var(--font-family);
}
.white {
  background: #fff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 14px !important;
}
.primary {
  background: var(--primary-color) !important;
  color: #fff !important;
  border: 1px solid var(--primary-color) !important;
}
.primary:hover {
  background: var(--secondary-color);
  color: #fff;
  border: 1px solid var(--secondary-color);
}

.secondary {
  background: var(--secondary-color);
  color: #fff;
  border: 1px solid var(--secondary-color);
}

.black {
  background: #202020;
  color: #fff;
  border: 1px solid #202020;
}

.light_white {
  background: #f9f9f9;
  color: #727272;
  border: 1px solid #d9d9d9;
}

.dark_white {
  background: #f9f9f9;
  color: var(--dark-color);
  border: 1px solid #d9d9d9;
}
.dark_white:hover {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}

.button_custom_round {
  padding: 9px 18px;
  border-radius: 50px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}
.button_custom {
  padding: 10px 30px;
  border-radius: 100px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  height: 38px;
}
.button_custom_large {
  padding: 14px 30px;
  border-radius: 6px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}
.button_custom_med {
  padding: 18px 30px;
  border-radius: 50px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.button_custom_large_rount {
  padding: 22px 50px;
  border-radius: 50px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}

.flex_form_double_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  clear: both;
  width: 100%;
}
.flex_form_double_row .form-group {
  width: 48%;
}

.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 10px 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  z-index: 8;
  box-sizing: border-box;
}
.header_fixed_login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 5px 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  z-index: 8;
  box-sizing: border-box;
}

.mobile_view {
  display: none;
}

.logo img {
  width: 160px;
}

.buttons_login {
  display: flex;
  gap: 8px;
  align-items: center;
}

.form_middle {
  margin: 50px auto;
  width: 30%;
}
.center_button_custom_width {
  margin: auto;
  width: 70%;
  margin-top: 20px;
}
.center_button_custom_width button {
  height: 53px !important;
  font-size: 16px;
}

.page_middle {
  width: 60%;
  margin: auto;
}

.go_back_icon {
  position: absolute;
  top: 84px;
  cursor: pointer;
  left: 19px;
}

.onboard_heading h4 {
  color: #000;
  text-align: center;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.onboard_heading h1 {
  color: #f99602;
  text-align: center;
  font-size: 77px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.icon_onboarding {
  margin-top: 30px;
}
.icon_onboarding img {
  cursor: pointer;
}

.balance_box {
  display: flex;
  align-items: flex-end;
  gap: 2px;
  margin-left: -67px;
}
.balance {
  color: #fff;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 13px;
  background: var(--primary-color);
  padding: 12px 10px;
  border: 2px solid #202020;
  letter-spacing: 2px;
  cursor: pointer;
  width: 110px;
  text-align: center;
  height: 25px;
}
.icon_info {
  cursor: pointer;
}
.show_on_hover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px;
}
.show_on_hover a {
  display: flex;
}
.show_on_hover button {
  background: #fff;
  border: none;
  border-radius: 100px;
  font-size: 11px;
  padding: 4px 6px;
}

.user_dropdown {
  margin-right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  position: relative;
}
.user_dropdown:hover {
  color: var(--light-color);
}
.setting_icon {
  cursor: pointer;
}
.setting_icon:hover {
  color: var(--primary-color);
}

.user_dropdown_box {
  position: absolute;
  top: 50px;
  background: #fff;
  padding: 10px;
  border: 1px solid #f0f0f0;
  transition: 0.5s ease-in;
  right: 4px;
}
.links_dropdown {
  position: relative;
}
.links_dropdown a {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 7px 0;
  color: var(--dark-color);
  border-bottom: 1px dotted #f0f0f0;
}
.links_dropdown a:hover {
  color: var(--primary-color);
}

.Sublanguage {
  position: absolute;
  height: max-content;
  width: 100%;
  transition: 0.5s ease-in;
  background: #f6f6f6;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.25);
  left: -120%;
  top: 0;
  padding: 5px;
  cursor: pointer;
}

.user_setting_box {
  position: absolute;
  top: 50px;
  padding: 10px;
  border: 1px solid #f0f0f0;
  transition: 0.5s ease-in;
  background: #f6f6f6;
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 300px;
  left: -200px;
}

.user_setting_box h4 {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
}

.section_setting_drop {
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  margin-top: 10px;
  float: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section_setting_drop label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 0px;
}
.space-setting {
  justify-content: space-between !important;
  align-items: center;
}
.column_setting {
  flex-direction: column;
}
.column_setting label {
  gap: 10px;
  padding-bottom: 15px;
}
.column_setting label:last-child {
  padding-bottom: 0;
}
.plus_minus_flex {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
.plus_minus_flex span {
  font-size: 11px;
}

.spending_limit {
  display: flex;
  align-items: center;
  gap: 10px;
}
.spending_limit input {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 40px;
  border-radius: 3px;
  text-align: center;
  height: 22px;
}
.spending_limit select {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 50px;
  border-radius: 3px;
  text-align: center;
  height: 22px;
  font-size: 10px;
}

.boxes_onboard {
  border: 1.5px solid var(--dark-color);
  border-radius: 5px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: #fff;
  cursor: pointer;
}
.boxes_onboard img {
  width: 150px;
}
.boxes_onboard .text_onboard {
  color: var(--primary-color);
  font-size: 26px;
  font-weight: 600;
}

.boxes_onboard:hover {
  border: 1.5px solid var(--primary-color);
}

.my_languages_wrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 30px;
}
.languages_my {
  width: 47%;
}
.languages_my .card {
  width: 100%;
}
.heading_languages {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 18px;
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}
.heading_languages span {
  display: flex;
}

.lang_my_selection .title_ {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-top: 7px;
  font-weight: 700;
  font-size: 15px;
}

.content_languages {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 33vh);
  overflow: auto;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.image_lang img {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
}

.outer_wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.inner_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.inner_wrapper_bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  box-sizing: border-box;
}
.white_wrapper {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  min-height: 20%;
  max-height: 70%;
}
.inner_wrapper .white_wrapper {
  border-radius: 10px !important;
  padding: 0px 0px 10px !important;
}
.choose_lang_ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.choose_lang_ .image_lang img {
  width: 150px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
}
.footer_languages {
  border-top: 1px solid #f0f0f0;
  margin-top: 25px;
  padding: 10px 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}
.check_green {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 19px;
  color: var(--primary-color);
}

.langugae_courses_heading {
  text-align: center;
  font-size: 22px;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  gap: 20px;
  margin-left: 1px;
}
.ac_mg_leg {
  margin-left: -38px !important;
}
.icon_language_settings {
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 15px;
}
.content_languages_details {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}
.numbers_table {
  padding: 10px 15px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 10px;
}
.numbers_table_td {
  padding: 8px 15px;
  border-bottom: 1px solid #f6f6f6;
  position: relative;
}
.numbers_table_td .col1 {
  width: 13%;
}
.numbers_table_td .col2 {
  width: 75%;
}
.numbers_table_td .col3 {
  width: 12%;
  text-align: right;
}
.slash_new {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  width: 100%;
}
.pointer_left_abs {
  position: absolute;
  left: -18px;
  top: 7px;
}

.languages_my_details {
  height: calc(100vh - 35vh);
}
.languages_my_details .card {
  width: 100%;
  height: 100%;
}

.languages_my_details:first-child {
  width: 25%;
}
.languages_my_details:last-child {
  width: 40%;
}

.languages_my_details:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.line_height_20 {
  line-height: 20px;
}

.left_sentence_pills {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.pill_circle {
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 8px 15px;
  border-radius: 50px;
  min-width: 60px;
  text-align: center;
  font-size: 12px;
}
.pill_circle.dark {
  border: 1px solid #ccc;
}

.nouns {
  color: rgba(103, 95, 95, 0.37);
}
.pronouns {
  color: #458855;
}
.verbs {
  color: #b97a57;
}
.adverbs {
  color: #cf1b1b;
}
.adjectives {
  color: #28cd19;
}
.prepositions {
  color: #2f21da;
}
.conjunctions {
  color: #ba1fb4;
}
.interjections {
  color: #f8afce;
}
.articles {
  color: #31c6da;
}
.determiners {
  color: #c2cf27;
}

.sentence_data {
  display: flex;
  align-items: center;
  border: 2px solid #ebebeb;
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 24px;
  margin-bottom: 10px;
}
.sentence_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 70%;
  margin: auto;
  margin-top: 30px;
}
.right_sentences_data {
  width: 90%;
}
.play_icons {
  width: 9%;
}
@keyframes flash {
  0%,
  49%,
  100% {
    border: 2px solid var(--primary-color);
  }
  50%,
  99% {
    border: 2px solid var(--primary-color);
  }
}

.flashing-div {
  animation: flash 1s infinite;
}

.count_down {
  text-align: center;
  font-size: 90px;
  padding: 3px;
  font-weight: bold;
}
.count_downflex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.icons_sentence {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.icon_set {
  font-size: 18px;
}
.flag_reasons label {
  padding: 6px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.flag_textarea {
  margin-top: 10px;
}
.flag_textarea textarea {
  height: 80px !important;
  border-radius: 5px;
}

.flag_textarea textarea::placeholder {
  color: #c6c6c6;
}

.comment_left_wrap {
  width: 90%;
}
.sentence_data_normal {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 24px;
  margin-bottom: 15px;
  gap: 20px;
}
.sentence_data_normal .sound_icon {
  display: flex;
  cursor: pointer;
}
.sentence_data_normal .sound_icon:hover {
  color: var(--primary-color);
}

.active_hover {
  color: var(--primary-color) !important;
}

.sentence_data_normal .content_sond {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nouns_settings {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 3%;
}

.comment_area {
  width: 70%;
  margin: auto;
}
.comment_area textarea {
  height: 100px;
  border-radius: 5px;
}

textarea::placeholder {
  color: #c6c6c6 !important;
}

.comments_section {
  padding: 30px;
  margin-top: 15px;
}
.comments_section h4 {
  font-size: 18px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 10px;
}

.comments_outer_wrap {
  margin-top: 30px;
}
.comments_wrap {
  margin-top: 20px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
}
.comments_wrap:last-child {
  border-bottom: none !important;
}
.comments_wrap .name_commenter {
  color: #2dc133;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.comments_wrap .comment_text {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.comments_wrap .comment_icons {
  display: flex;
  align-items: center;
  gap: 25px;
}
.up_down_arrows {
  display: flex;
  align-items: center;
  gap: 2px;
}
.arrow_up:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.reply_board {
  margin-left: 73px;
  padding: 12px 25px;
  background: #f5f5f5;
  border-radius: 12px;
  margin-top: 10px;
  border: 1px solid #f0f0f0;
}
.reply_board h6 {
  font-size: 12px;
  margin-bottom: 9px;
}
.reply_board textarea {
  border-radius: 5px !important;
}
.reply_comment {
  cursor: pointer;
}
.reply_comment:hover {
  color: var(--primary-color);
}

.languages_header_bar {
  margin-left: 0px;
}
.center_lang_section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.center_lang_section img {
  width: 40px;
  cursor: pointer;
}

.left_lang_section {
  display: flex;
  align-items: center;
  gap: 10px;
}
.left_lang_section img {
  width: 24px;
}

.buttons_earn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 7%;
}

.buttons_earn button {
  width: 30%;
  height: 40px;
  cursor: pointer;
}

.previous_sentences .senetence_ {
  padding: 7px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.box_left_add_ {
  position: relative;
}
.box_left_add_:first-child {
  width: 25%;
}
.box_left_add_:nth-child(2) {
  width: 55%;
}

.box_left_add_:last-child {
  width: 25%;
}

.tags_left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tags_left label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tags_button_next {
}
.sentence_source {
  padding: 15px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: var(--light-color);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sentence_source span {
  padding: 7px 10px;
}

.add_sentence_source {
  margin-top: 20px;
}

.question_mark_ {
  position: absolute;
  right: -17px;
  margin-top: 4px;
  font-size: 14px;
  color: #44d0f4;
}

.langword_bottom {
  float: left;
  display: flex;
  gap: 10px;
  align-items: center;
  clear: both;
  margin-top: 13px;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 16px;
  cursor: pointer;
}
.borderclass {
  border: 1px solid #f0f0f0;
  padding: 0px 10px;
  border-radius: 5px;
}

.button_tags button {
  min-width: 125px;
}

.blacktext {
  color: #202020;
}
.green {
  color: #10d730;
}

.words_source_flex_data {
  display: flex;
  align-items: center;
  gap: 8px;
}

.show_old_new {
  clear: both;
  margin-top: 20px;
  float: left;
  width: 100%;
  font-weight: bold;
}

.text_user_show img {
  width: 22px;
}
.microphone_divs {
  font-size: 18px;
}

.microphone_width {
  width: 70%;
  text-align: center;
  margin-right: -50px;
}
.custom_mar {
  margin-top: 100px;
}

.checks_buttons {
  clear: both;
  float: left;
  margin-top: 20px;
  width: 100%;
}

.yes_button {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  background: green;
  border: none;
  color: #fff;
}
.no_button {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  background: red;
  border: none;
  color: #fff;
}

.float-left {
  float: left;
}

.overflow_table_content {
  /* height: calc(100% - 13%);
    overflow: auto; */
}

.langugae_courses_heading img {
  width: 18px;
}

.price_plus_bonus {
  color: #202020;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.left_deposit_ {
  border-right: 1px solid #f0f0f0;
  padding: 20px;
}

.light_text_restriction {
  color: var(--light-color);
  line-height: 20px;
}

.input_deposit {
  margin-top: 20px;
  margin-bottom: 30px;
  float: left;
}
.deposit_input {
  border: 1px solid #f0f0f0;
  padding: 12px;
  border-radius: 5px;
}
.abs_symbol {
  position: absolute;
  top: 1px;
  right: 1px;
  background: #fff;
  padding: 12px;
  color: #b6b6b6;
}

.method_wrap {
  clear: both;
}
.methods_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  margin-top: 22px;
}
.method_box {
  background: #f0f0f0;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 7px;
  min-width: 30%;
  cursor: pointer;
}
.method_box:hover {
  background: var(--primary-color);
  color: #fff;
}
.method_box_hover {
  background: var(--primary-color);
  color: #fff;
}
.withdrawal_info {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
  line-height: 20px;
}

.custom_paypal {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
}

.verifiy_form {
  margin: auto;
}
.verifiy_form .form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}
.verifiy_form .form-group label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.verifiy_form .form-group input,
.verifiy_form .form-group select,
.verifiy_form .form-group textarea {
  padding: 10px 18px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  font-family: var(--font-family);
}
.verifiy_form .form-group textarea {
  height: 100px;
}

.upload_box {
  border: 1px dashed #ccc;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  flex-direction: column;
  gap: 15px;
}
.upload_box:hover {
  border: 1px dashed var(--primary-color);
}

.abs_icon_view {
  position: absolute;
  right: 9px;
  top: 33px;
  font-size: 23px;
  cursor: pointer;
}
.abs_icon_view:hover {
  color: var(--primary-color);
}

.amount_big {
  color: #202020;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.5px;
  margin-bottom: 40px;
}
.small_amount {
  color: #202020;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.65px;
}

.button_transactions button {
  background: green;
  color: #fff;
  border: none;
  padding: 13px 39px;
}
.button_transactions button:hover {
  background: var(--primary-color);
}
.transaction_amt p {
  color: #545454;
  font-size: 16px;
  margin-bottom: 10px;
}
.tabs_wrap .tab_one {
  cursor: pointer;
  padding-bottom: 10px;
}
.tab_one_hover {
  border-bottom: 1px solid var(--primary-color);
}
.tabs_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  color: #545454;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.05px;
  padding: 0 0 0px 0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 20px;
}

.table_transactions {
  width: 100%;
}

.table_transactions td,
th {
  text-align: left;
  padding: 10px;
}

.mobile_left_popup {
  display: none;
}

.image_credits {
  position: absolute;
  bottom: 12px;
  left: 9px;
}

.hover_red:hover {
  color: red !important;
}

.cancel_submiy_bytn {
  display: flex;
  align-items: center;
  gap: 10px;
}
.can_bbbtn {
  font-size: 10px;
  color: red;
  cursor: pointer;
}

.boldtext {
  font-weight: bold;
}

.button_custom_large_rount:disabled {
  background: #ccc !important;
  border: inherit !important;
}

.no_text {
  color: red;
  text-align: center;
  width: 100%;
  padding: 40px;
}

#question_new_sentence {
  text-align: "center";
  padding: "13px 10px";
  font-size: "13px";
  z-index: 9;
  width: 19vw;
}

@media (max-width: 768px) {
  body {
    margin-top: 150px;
  }
  .languages_my_details {
    height: 100% !important;
  }
  .header_fixed .wrapper_full,
  .boxes_onboard,
  .languages_my,
  .languages_my_details,
  .header_fixed_login .wrapper_full {
    width: 100% !important;
    box-sizing: border-box;
  }
  .wrapper_full,
  .wrapper {
    width: 90%;
  }
  .form_middle,
  .comment_area,
  .comment_area button,
  .comments_section,
  .comment_left_wrap,
  .buttons_earn button,
  .box_left_add_ {
    width: 100% !important;
    box-sizing: border-box;
  }
  #question_new_sentence {
    width: 90%;
  }
  .Sublanguage {
    left: -10px;
    top: 190px;
    cursor: pointer;
  }
  .icon_onboarding .flex-center,
  .my_languages_wrap {
    flex-direction: column;
  }
  .choose_lang_ .image_lang img {
    width: 116px !important;
  }
  .choose_lang_ {
    height: 450px;
    overflow: auto;
  }
  .white_wrapper.w40 {
    width: 95%;
    box-sizing: border-box;
  }
  .sentence_wrapper {
    width: 100%;
    flex-direction: column;
  }
  .sentence_data,
  .sentence_data_normal,
  .sentence_data_normal .content_sond {
    flex-wrap: wrap;
    justify-content: center;
  }
  .sentence_wrapper {
    margin-top: 0;
  }
  .card.p20 {
    padding: 10px;
  }
  .comments_wrap {
    margin-left: 5px !important;
  }
  .comments_section {
    padding: 5px;
  }
  .nouns_settings {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
  }
  .tags_button_next {
    flex-direction: column;
    gap: 30px;
  }
  .button_tags button,
  .button_tags {
    width: 100%;
  }
  .tags_left {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .langword_bottom {
    margin-left: 0% !important;
  }
  .custom_tags_button {
    flex-direction: column;
    width: 100%;
  }
  .play_icons {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
  }
  .new_desktop {
    display: none;
  }
  .mobile_view {
    display: block;
    width: 100%;
  }
  .top_logo_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .no_mobile img {
    height: 30px;
  }
  .mobile_left_popup {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    z-index: 99;
    background: #fff;
    width: 75%;
    padding: 40px 20px;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.4);
  }
  .buttons_login {
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
  }
  .user_setting_box {
    position: absolute;
    top: 106px;
    padding: 10px;
    border: 1px solid #f0f0f0;
    transition: 0.5s ease-in;
    background: #f6f6f6;
    box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 290px;
    left: -5px;
    box-sizing: border-box;
  }
  .user_dropdown_box {
    position: absolute;
    top: 25px;
    background: #fff;
    padding: 10px;
    border: 1px solid #f0f0f0;
    transition: 0.5s ease-in;
    right: 4px;
    width: 297px;
    float: left;
    left: 0;
    box-sizing: border-box;
  }
  .cls_icon_leftbar {
    width: 100%;
    margin-top: -28px;
    color: red;
    margin-right: -8px;
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .mobile_left_popup {
    display: block;
  }
  .deposit_withdrawal {
    flex-direction: column;
  }
  .left_deposit_,
  .method_box {
    width: 100% !important;
    box-sizing: border-box;
    border-right: none;
  }
  .input_deposit {
    width: 100% !important;
    box-sizing: border-box;
  }
  .deposit_input,
  .method_box_hover,
  .verifiy_form {
    width: 100% !important;
    box-sizing: border-box;
  }
  .icon_onboarding {
    margin-bottom: 100px;
    float: left;
    width: 100%;
  }
  .icon_onboarding .gap_45 {
    gap: 20px;
  }
  .onboard_heading h4 {
    font-size: 30px;
  }
  .onboard_heading h1 {
    font-size: 55px;
  }
  .wrapper {
    padding-bottom: 80px;
  }
  .white_wrapper {
    max-height: 90% !important;
  }
  .methods_list .w35 {
    width: 100%;
  }
  .image_credits {
    position: static;
    text-align: center;
    padding: 20px;
  }
}

.colum_custom_bototm {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}